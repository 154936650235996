import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const Item = (props) => {
  const navigate = useNavigate();
  const { title, contents } = props;

  let that = window;

  return (
    <div className={styles.item}>
      <h4 className={styles.title}>{title}</h4>
      <div className={`${styles.content}`}>
        {contents.map((v) => {
          return (
            <div
              className={v.path ? "pointer" : ""}
              onClick={
                v.path
                  ? () =>
                      v.outline
                        ? (that.location.href = v.path)
                        : navigate(`${v.path}`)
                  : null
              }
              key={v.name}
            >
              {v.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Item;
