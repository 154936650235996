import React from "react";
import { Col, Row, Carousel, Layout } from "antd";
import CarouselContent from "@/components/CarouselContent";
import { Mobile } from "@/utils/window";
// css
import styles from "./index.module.css";
// photo
import {
  anchor,
  anchorMini,
  indent,
  indentMini,
  supplier,
  supplierMini,
  weibo,
  weixin,
} from "@/utils/image";

const { Content } = Layout;
const Home = () => {
  const isMobile = Mobile();
  return (
    <>
      <div className={styles.mainFull}>
        <Content>
          <div className={styles.main}>
            <Row>
              <Col offset={isMobile ? 0 : 2} span={isMobile ? 24 : 20}>
                <Carousel autoplay>
                  <CarouselContent
                    isMobile={isMobile}
                    h1="专职主播招募"
                    h2="品质优选，严格把控"
                    text="为新手主播提供快速成长秘诀，详解直播玩法规则，打造主播专属个人直播间"
                    photo={isMobile ? anchorMini : anchor}
                  />
                  <CarouselContent
                    isMobile={isMobile}
                    h1="订单实时监控"
                    h2="品质优选，严格把控"
                    text="高效、专业的全渠道电商服务平台，场景灵活应用，持续稳定迭代更新，实时订单监控跟踪"
                    photo={isMobile ? indentMini : indent}
                  />
                  <CarouselContent
                    isMobile={isMobile}
                    h1="供应商审核"
                    h2="品质优选，严格把控"
                    text="平台将根据评估矩阵对供应商的商品进行资格预审，以帮助他们将质量提高到可接受的水平"
                    photo={isMobile ? supplierMini : supplier}
                  />
                </Carousel>
              </Col>
              <Col span={isMobile ? 0 : 2}>
                <Row gutter={0} className={styles.connect}>
                  <Col span={24}>
                    <img className={styles.weibo} src={weibo} alt="" />
                  </Col>
                  <Col span={24}>
                    <img className={styles.weixin} src={weixin} alt="" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Content>
      </div>
    </>
  );
};
export default Home;
