import React from "react";
import { Row, Col } from "antd";
import Partner from "@/components/Partner";
import styles from "./index.module.css";

export default function CarouselContent(props) {
  const { isMobile, h1, h2, text, photo } = props;

  return (
    <div className={styles.carouselContent}>
      <Row>
        <Col span={isMobile ? 18 : 10} className={styles.contentLeft}>
          <div className={styles.contentText}>
            <div className={styles.h1}>{h1}</div>
            <div className={styles.h2}>{h2}</div>
            <div className={styles.text}>{text}</div>
          </div>
          <Partner />
        </Col>
        <Col span={isMobile ? 6 : 14} className={styles.contentRight}>
          <img className={styles.img} src={photo} alt="" />
        </Col>
      </Row>
    </div>
  );
}
