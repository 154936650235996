import React, { useState } from "react";
import { Col, Row, Layout, Button, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { Mobile } from "@/utils/window";

import styles from "./index.module.css";

import { cibn, logoimg, menuOpen, menuClose } from "@/utils/image";

const data = [
  { label: "首页", path: "/home" },
  { label: "简介", path: "/info" },
  { label: "短剧", path: "/playlet" },
  { label: "学习中心", path: "/study", target: "_blank" },
];

const Header = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const isMobile = Mobile();

  const handleMenuClick = () => {
    setVisible(!visible);
  };

  let that = window;
  const items = data.map((item) => {
    return {
      label: item.label,
      onClick: () => {
        if (item.target) {
          that.location.href = item.path;
        } else {
          navigate(item.path);
        }
        setVisible(false);
      },
      style: { color: "#fff" },
    };
  });

  return (
    <div>
      {isMobile ? (
        <div>
          <div className={styles.nav}>
            <img
              src={visible ? menuClose : menuOpen}
              className={`${styles.navLeft} pointer`}
              onClick={handleMenuClick}
              alt=""
            />
            <img
              src={logoimg}
              className={`${styles.navRight} pointer`}
              onClick={() => navigate("/")}
              alt=""
            />
          </div>
          <div className={styles.navContent}>
            <Menu
              mode="inline"
              theme="dark"
              style={{
                display: visible ? "block" : "none",
                position: "fixed",
                zIndex: 999,
                transition: "all 1s ease-in-out",
              }}
              items={items}
            ></Menu>
          </div>
        </div>
      ) : (
        <Layout.Header className={styles.headerStyle}>
          <Row>
            <Col span={8}>
              <div>
                <img
                  onClick={() => navigate("/")}
                  className={`${styles.img} pointer`}
                  src={cibn}
                  alt=""
                />
              </div>
            </Col>
            <Col span={16}>
              {data.map((item) => {
                return (
                  <Button
                    style={{ marginRight: 30 }}
                    type="link"
                    size="large"
                    href={item.path}
                    target={item.target}
                    key={item.label}
                  >
                    {item.label}
                  </Button>
                );
              })}
            </Col>
          </Row>
        </Layout.Header>
      )}
    </div>
  );
};

export default Header;
