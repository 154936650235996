import "./App.css";
import { Routes, Route } from "react-router-dom";
import Terms from "@/pages/Terms";
import Privacy from "@/pages/Privacy";
import Anchor from "@/pages/Anchor";
import Supplier from "@/pages/Supplier";
import MerChant from "@/pages/Merchant";
import Container from "@/pages/Container";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/anchor" element={<Anchor />}></Route>
        <Route path="/merchant" element={<MerChant />}></Route>
        <Route path="/supplier" element={<Supplier />}></Route>
        {/* default route */}
        <Route path="*" element={<Container />}></Route>
      </Routes>
    </div>
  );
}

export default App;
