import React from "react";
import styles from "./index.module.css";

import { iconCool, iconTencent, iconYouku } from "@/utils/image";

export default function Partner() {
  return (
    <div>
      <h1 className={styles.title}>合作伙伴</h1>
      <div className={styles.content}>
        <img src={iconCool} alt="" />
        <img src={iconTencent} alt="" />
        <img src={iconYouku} alt="" />
      </div>
    </div>
  );
}
