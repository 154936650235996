import React from "react";
import styles from "./index.module.css";

const Title = (props) => {
  const { isMobile, titleColor, underLineColor, children } = props;
  return (
    <>
      <h1
        className={styles.title}
        style={{
          color: titleColor,
          marginTop: isMobile ? 40 : 100,
          marginBottom: isMobile ? 0 : 10,
          fontSize: isMobile ? 25 : 40,
          width: isMobile ? 100 : 160,
        }}
      >
        {children}
      </h1>
      <div
        className={styles.underLineShort}
        style={{
          background: underLineColor,
          width: isMobile ? 20 : 40,
          height: isMobile ? 3 : 4,
        }}
      />
      <div
        className={styles.underLineLong}
        style={{
          marginBottom: isMobile ? 20 : 100,
          width: isMobile ? 140 : 200,
        }}
      ></div>
    </>
  );
};

export default Title;
