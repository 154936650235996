import React from "react";
import "./index.css";
const Supplier = () => {
  return (
    <>
      <div className="supplier"></div>
    </>
  );
};
export default Supplier;
