import React from "react";
import "./index.css";
const Anchor = () => {
  return (
    <>
      <div className="anchor">
        <h1 style={{ textAlign: "center", fontSize: 40, marginBottom: 50 }}>
          中广直播间 主播筛选基本原则及流程
        </h1>
        <h2>一、 主播筛选基本原则</h2>
        <p className="text">1.主播年龄：不得低于18周岁；</p>
        <p className="text">2.主播来源</p>
        <p className="text-4">
          （1）优选广电体系内电视台主持、播音主持作为大屏直播电商的主播人选；
        </p>
        <p className="text-4">
          （2）以直播平台播出规模大于100万用户的主播为选择范围，重点选择第三方直播平台专业的电商型主播，包括天猫、抖音、MCN合作主播等；（需提供证明材料，合作协议等）；
        </p>
        <p className="text-4">（3）由业务部门初选的个人主播等；</p>
        <p className="text">
          3.主播个人信息筛查：包括实名认证、从业时间、个人信誉、专业认证、所在企业机构、有无不良记录等；
        </p>
        <p className="text">
          4.网络关注度评估：包括淘宝达人昵称及粉丝量、新浪微博昵称及粉丝量、抖音、小红书等其他平台昵称及粉丝量；
        </p>
        <p className="text">
          5.从业经历审核：主播各平台等级、经验值、直播榜单、综合评分、直播间品质分等；
        </p>
        <p className="text">6.掌握相关专业技能；</p>
        <p className="text">7.经过相关机构专业培训；</p>
        <p className="text">8.取得相关机构核准证书；</p>
        <p className="text">9.熟悉有关法律规定；</p>
        <p className="text">
          10.对参与直播的嘉宾选择需把政治素养、道德品行、专业水准、社会评价作为选用标准，不得选用未成年人及违法失德艺人参与直播。
        </p>
        <h2>二、 主播筛选流程</h2>
        <p className="text">
          中广直播间主播人员由业务部门负责选送，经业务部门对主播及主播所在MCN机构和平台进行预先调研、初步审核后，将通过筛选的主播名单及相关信息提交至总编室。总编室完成信息审核后下发注册邀请码，主播需自主完成账号注册，与国广鑫华线上签订《“中广直播间”直播主播入驻协议》并进行培训考核（线上或线下）。总编室组织电商主播评审管委会对考核通过的主播进行终审并颁发直播编号。总编室根据相关管理机构的要求，定期审核报备中广直播间入驻主播相关信息。
        </p>
      </div>
    </>
  );
};
export default Anchor;
