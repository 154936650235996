import React from "react";
import "./index.css";
const MerChant = () => {
  return (
    <>
      <div className="merchant">
        <h1 style={{ textAlign: "center", fontSize: 40, marginBottom: 50 }}>
          中广直播间电商合作协议
        </h1>
        <table>
          <tr>
            <th className="headTable">
              甲方：国广鑫华科技（北京）有限公司
              <br />
              （以下简称“甲方”）
            </th>
            <th className="headTable">
              乙方：
              <br />
              （以下简称“乙方”）
            </th>
          </tr>
          <tr>
            <td className="headTable">法定代表人：</td>
            <td className="headTable">法定代表人：</td>
          </tr>
          <tr>
            <td className="headTable">联系人：</td>
            <td className="headTable">联系人：</td>
          </tr>
          <tr>
            <td className="headTable">地址：</td>
            <td className="headTable">地址：</td>
          </tr>
          <tr>
            <td className="headTable">邮箱：</td>
            <td className="headTable">邮箱：</td>
          </tr>
          <tr>
            <td className="headTable">电话：</td>
            <td className="headTable">电话：</td>
          </tr>
        </table>
        {/* <h2>一、 主播筛选基本原则</h2> */}
        <p className="text">
          依据相关法律法规，甲乙双方一致同意，在互联网电视电商直播平台进行合作，共同发展互联网电视电商直播“中广直播间”业务（以下简称“合作业务”），经友好协商，双方达成本合作协议。
        </p>
        <h2>第一条 定义</h2>
        <p className="text">若无特别约定，本协议中以下词语和表达含义如下：</p>
        <p className="text">
          1.
          信息：是指本协议一方当事人，按照本协议向对方提供并许可其使用的音视频、文字、图片、数据、软件及其他相关资料。
        </p>
        <p className="text">
          2.
          互联网电视电商直播“中广直播间”业务：特指经国家广播电视总局网络视听节目管理司批准的，具备相应运营资质的在互联网电视终端上开展的“中广直播间”电商直播业务，以下简称“中广直播间”。
        </p>
        <p className="text">3. CIBN开播：指“中广直播间”业务的产品名称。</p>
        <p className="text">
          4. 用户：指使用“中广直播间”平台的自然人、法人、其他组织等。
        </p>
        <p className="text">
          5.
          工作日:国家所规定的节假日之外的所有工作日，未指明为工作日的日期指自然顺延的日期。
        </p>
        <p className="text">
          6.
          互联网电视：是指以广域网即传统互联网或移动互联网为传输网络，以电视机为接收终端，向用户提供视频及图文信息内容等服务的电视形态。
        </p>
        <p className="text">
          7.
          直播间店铺：为进行合法经营，依据本协议约定和平台规则，由乙方申请在“中广直播间”平台内开设的自有商品/合作商品电商内容展示频道。
        </p>
        <p className="text">
          8.
          商家账户：是指乙方在“中广直播间”平台完成商家注册后获取的与自设密码共同使用以登陆“中广直播间”商家管理系统的用户名，每一个管理系统用户名对应一个店铺。商家应妥善保管系统用户名及密码信息，不得擅自转让或授权他人使用，并对其店铺用户名下的一切行为独立承担责任。
        </p>
        <p className="text">
          9.
          平台规则：指“中广直播间”平台已经制定发布或将来可能发布的与商家经营有关的各种规范性文件，包括但不限于乙方使用本协议项下平台相关功能所应遵守的相应规则及其他细则、规范、政策、通知、公告等规范性文件及其调整后的最新文件。
        </p>
        <p className="text">
          10.
          保证金：指乙方向甲方缴纳的用以保证本协议履行及对商品和服务质量进行担保的资金，甲方可以依照本协议的相关约定对其进行处置。
        </p>
        <p className="text">
          11.
          结转销售：是指“中广直播间”平台售出商品后客户已签收并支付款项，且未办理退货手续的商品销售。如客户后期办理退货的，退货额自当期结转销售额中扣除。
        </p>
        <h2>第二条 合作内容</h2>
        <p className="text">
          1.
          甲乙双方同意充分发挥和整合各自领域的能力、资源与优势，在“中广直播间”平台内开设直播间店铺，甲方为乙方提供互联网电视电商直播运营后台，乙方通过后台操作实现在“中广直播间”平台进行直播购物内容或点播、轮播购物内容的播出及商品销售。
        </p>
        <p className="text">
          2.
          甲方负责为合作业务提供合法的互联网电视电商直播业务许可平台。乙方负责提供拥有合法授权、符合法律法规规定、符合甲方监播管理要求的购物视听内容，负责运营商品销售、客服、配送和售后、为消费者开具发票等商品销售工作。
        </p>
        <p className="text">
          3.
          甲方配合协助乙方进行合作业务的技术对接，包括但不限于：电商内容数据对接、支付系统、用户和商品销售数据等对接。
        </p>
        <h2>第三条 入驻条件</h2>
        <p className="text">
          1.
          乙方申请成为“中广直播间”平台入驻商家，并开展经营活动，须持续满足以下条件：
        </p>
        <p className="text-4">
          (1)
          乙方已依照中华人民共和国法律注册并领取合法有效的营业执照及其他经营许可，身份信息应为商家自身情况的客观表现，具备签署、履行本协议的合法资格；
        </p>
        <p className="text-4">
          (2)
          乙方申请经营的商品来源合法，资质齐全、有效，对于特殊商品的宣传销售，已完成法律法规等规定的审批、批准、备案等必要手续；
        </p>
        <p className="text-4">
          (3)
          乙方同意本协议及“中广直播间”平台相关规则，符合“中广直播间”平台相关规则的要求。
        </p>
        <p className="text">
          2.
          乙方作为“中广直播间”平台入驻商家开展经营活动，应当向甲方提交以下证明文件：
        </p>
        <p className="text-4">
          (1)
          乙方须根据相关法律规定、“中广直播间”平台相关规则及要求向甲方提交证明文件或其他相关证明，包括但不限于营业执照、授权委托书、商标注册证、质检报告、报关单、检验检疫证书、产品来源地证明等。
        </p>
        <p className="text-4">
          (2)
          乙方保证向甲方提供的上述证明文件及其他相关证明真实、合法、准确、有效，并保证上述证明文件及其他相关证明发生任何变更或更新时，及时（取得最新文件后三日内）向甲方提供更新后的文件，若上述文件变更或更新导致乙方不满足本协议所约定入驻条件的，甲方有权单方终止本协议，或者全部或部分限制乙方经营，直至乙方满足本协议所约定的入驻条件。若因乙方提交虚假、过期文件或未及时更新证明文件、未及时发出通知导致纠纷或被相关国家机关处罚的，由乙方独立承担全部法律责任，如因此造成甲方（包括甲方合作方、代理人或职员）损失的，乙方应予以赔偿。
        </p>
        <p className="text">
          3.
          乙方应当妥善保管商家账户，确保在每个上网时段结束时退出登录并以正确步骤离开“中广直播间”电商运营平台。因乙方泄露账户信息或因乙方遭受他人攻击、诈骗等行为导致的损失及后果，甲方不承担责任。乙方应对乙方账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、销售商品及服务、提供服务、披露信息等）负责。
        </p>
        <h2>第四条 店铺服务开通及停止</h2>
        <p className="text">
          1.
          乙方拟开展经营时，甲方在乙方提出申请并满足以下条件后为乙方开通服务。
        </p>
        <p className="text-4">
          (1) 乙方已按照本协议约定的金额和时间足额缴纳保证金；
        </p>
        <p className="text-4">
          (2)
          乙方已按照本协议及“中广直播间”平台相关规则提交证明文件或其他相关证明并已通过甲方审核；
        </p>
        <p className="text-4">
          (3) 乙方已注册成为“中广直播间”平台用户且本协议已签署生效。
        </p>
        <p className="text">
          2.
          甲方为乙方开通服务后，乙方可利用商家账户及自设密码登陆商家后台，根据“中广直播间”平台相关规则及流程向本协议及附件中涉及的乙方特定店铺上传、发布电商内容及商品信息，与用户交流达成交易，使用本协议约定的其他有偿服务等。
        </p>
        <p className="text">3. 乙方店铺服务的停止：</p>
        <p className="text-4">
          (1)
          乙方需要停止店铺服务的，应至少提前四十日向甲方提出书面申请，经甲方书面回复且乙方店铺最后一笔订单完成（消费者收货满七日视为该笔订单完成）90
          日后，无未决争议事项的，甲方审核同意后由甲方停止该店铺的服务功能；
        </p>
        <p className="text-4">
          (2)
          出现以下任一情形时，甲方有权随时停止乙方该店铺的服务而无需承担任何责任，并有权依据本协议约定没收乙方全部或部分保证金：
        </p>
        <p className="text-6">1) 乙方提供虚假资质文件的；</p>
        <p className="text-6">
          2) 乙方产品价格、规格等信息标示错误，导致行政处罚、争议和纠纷的；
        </p>
        <p className="text-6">
          3) 乙方产品质量、标识不合格的，或者产品假冒伪劣、旧货、返修品的；
        </p>
        <p className="text-6">
          4)
          在“中广直播间”平台上传、展示、销售未经甲方事先审核通过的商品、品牌的；
        </p>
        <p className="text-6">
          5)
          乙方连续30（三十）日或累计45（四十五）日未正常经营店铺的（包括但不限于乙方未对店铺商品类目及其介绍、展示、数量进行更新维护，未响应消费者购买、发货、退换货、退款等要求，未处理消费者投诉及其他店铺日常运营行为）；
        </p>
        <p className="text-6">
          6)
          甲方依据本协议及“中广直播间”平台相关规则进行争议等的处置后，乙方拒绝承担乙方应承担的费用的；
        </p>
        <p className="text-6">
          7)
          乙方通过虚增销量、订单量、用户好评等不正当手段提升乙方信誉、宣传推广其产品或者服务的；
        </p>
        <p className="text-6">8) 乙方对用户进行恶意骚扰或者泄露用户隐私的；</p>
        <p className="text-6">
          9)
          其他违反法律法规、相关规定、本协议约定或“中广直播间”平台规则的，或者其他侵犯甲方或消费者权益的。
        </p>
        <p className="text-4">
          (3)
          如甲方通过下架全部商品短暂关停乙方的店铺，乙方应当对店铺关停前已达成的交易继续承担发货、退换货及质保维修、维权投诉处理等交易保障责任。
        </p>
        <h2>第五条 甲方权利与义务</h2>
        <p className="text">
          1.
          甲方有权依照法律、法规、规章及其他规范性文件等的规定及“中广直播间”相关协议、平台规则的约定，负责对“中广直播间”平台的播控进行管理。
        </p>
        <p className="text">
          2. 甲方有权对“中广直播间”电商直播内容进行监播审核。
        </p>
        <p className="text">
          3.
          甲方有权按照法律/法规/规章等的规定及“中广直播间”相关协议、平台规则的约定，对“中广直播间”的乙方直播机构、主播人员、商品销售等进行管控，乙方及相关参与工作人员、事务须配合甲方上述管理要求。
        </p>
        <p className="text">
          4. “中广直播间”平台的著作权归甲方或相关权利方所有。
        </p>
        <p className="text">
          5. 甲方应确保“中广直播间”平台运行稳定，能够持续地向用户提供服务。
        </p>
        <p className="text">
          6.
          甲方负责为乙方提供“中广直播间”电商商家运营平台，包括“中广直播间”运营系统、客户端软件、内容及商品上线系统等，提供互联网电视用户落地播出等服务。在现有技术可实现的基础上维护“中广直播间”平台的正常稳定运行，并努力提升和改进技术，对平台功能及服务进行更新、升级，不断提升平台性能和交易效率。该更新升级可能影响乙方店铺服务的全部或部分功能，给乙方导致不便，乙方对此予以完全理解和接受，甲方对该更新升级造成乙方可能的影响、损失不承担责任。
        </p>
        <p className="text">
          7.
          甲方负责与乙方进行合作业务的技术对接，包括但不限于：视听内容数据对接、支付系统、用户和商品销售数据等对接。
        </p>
        <p className="text">
          8.
          甲方对乙方在使用“中广直播间”平台过程中遇到的问题及提出的建议和意见积极予以回复，可依乙方需求对其使用“中广直播间”平台提供合理的指导和培训。
        </p>
        <p className="text">
          9.
          甲方有权根据乙方营业执照所载明的经营范围及乙方申请的经营类目，核实及调整乙方在“中广直播间”平台经营的具体商品的种类和类目范围，乙方同意该项调整。
        </p>
        <p className="text">
          10.
          甲方有权自行或聘请第三方对乙方提供的相关资质文件等资料进行审核，乙方同意向甲方或甲方聘请的第三方提供相关资质文件，乙方应积极配合并承担由此产生的费用，甲方有权从乙方未结算款项中扣除，有权指示第三方支付机构直接扣划相应金额的商品销售价款。甲方审核为形式审核，审核通过并不代表甲方对审核内容的真实性、合法性、准确性、及时性的确认，乙方仍须对其提交的资料的真实性、合法性、准确性、及时性等承担相应的法律责任。
        </p>
        <p className="text">
          11.
          甲方有权在乙方出现下列情况时，对其采取暂停店铺运营、没收保证金、要求乙方支付违约金、终止本协议等本协议约定或平台规则规定的处理措施，上述措施不足以补偿甲方损失的，甲方有权继续向乙方追偿全部损失。
        </p>
        <p className="text-4">
          (1) 乙方发生违法、违规事件并经国家生效刑事、行政、民事法律文书确认的；
        </p>
        <p className="text-4">
          (2) 乙方累计2次违反法律、法规、规范性文件及相关监管规定的。
        </p>
        <p className="text-4">
          (3)
          乙方累计5次违反“中广直播间”规则的，包括但不限于《中广直播间-电商直播平台内容安全管理办法》《中广直播间-电商直播平台外请嘉宾管理办法（试行）》。
        </p>
        <p className="text">
          12.
          甲方有权根据市场情况及“中广直播间”平台入驻商家经营情况组织相应的促销活动，乙方应根据自身经营情况及商品情况积极予以支持。一旦乙方以书面方式（包括但不限于电子邮件、传真等）确认参与上述促销活动的，乙方不得中途退出促销活动，或采取商品下架等影响店铺正常运营或不利于消费者权益、不利于甲方促销活动开展的方式变相退出促销活动。
        </p>
        <p className="text">
          13.
          甲方有权要求乙方提供与乙方商品、服务等相关的信息，以便于客户直接向“中广直播间”平台客服中心进行咨询时予以回复，对于甲方无法回答或属乙方掌握的情况，甲方有权要求乙方在指定的时限内予以回复或给出相应方案，对乙方未及时解决的客户咨询及投诉给甲方及甲方的合作方造成损失或不良影响的，甲方有权自行或要求乙方采取相应处理措施消除不良影响，并有权要求乙方赔偿由此造成的损失。
        </p>
        <p className="text">
          14.
          甲方有权将合作中产生的因乙方原因引起的投诉移交乙方处理，并有权督促乙方妥善解决，因客户对于乙方产品质量的投诉给甲方造成的损失由乙方承担。
        </p>
        <p className="text">
          15.
          甲方有权要求乙方使用甲方支付通道等甲方指定的支付方式进行“中广直播间”平台商品销售的收款，乙方对此表示同意。
        </p>
        <p className="text">
          16.
          甲方有权限制乙方自主提现商品销售价款功能，有权指示第三方支付机构直接扣划相应金额的商品销售价款，用于支付应当由乙方承担的违约金、赔偿金、保证金、审核费用、技术服务费、有偿服务费等，乙方对此表示同意。
        </p>
        <p className="text">
          17.
          因网上交易平台的特殊性，甲方没有义务对所有乙方的交易行为以及与交易有关的其它事项进行事先审查，但下列情况除外：
        </p>
        <p className="text-4">
          (1)
          甲方认为乙方在“中广直播间”平台销售商品的品牌或在“中广直播间”平台进行的交易事项可能存在违反法律、法规、规章等相关规定或者违反“中广直播间”相关协议、平台规则的重大问题；
        </p>
        <p className="text-4">
          (2)
          第三方向甲方告知“中广直播间”平台上存在违法或不当行为的。甲方有权以普通非专业人员的知识水平标准对相关内容进行判别，认为这些内容或行为具有违法或不当性质的，甲方有权根据不同情况选择删除相关信息、对商家店铺采取限制性措施、停止对该商家提供店铺服务、终止本协议等措施。
        </p>
        <p className="text">
          18.
          双方合作期间，甲方及/或甲方的合作方、甲方指定的第三方有权通过乙方在“中广直播间”平台内提供的联系方式向乙方推荐相关产品/服务。
        </p>
        <p className="text">
          19.
          甲方有权根据国家相关法律法规、政策及平台运营情况，对“中广直播间”平台的规则、流程、收费标准等予以调整，乙方同意并认可甲方上述调整，甲方在平台通过公告形式公示上述调整事宜即视为甲方已经有效通知至乙方。
        </p>
        <p className="text">
          20.
          在保证乙方正常使用平台的情况下，甲方有权根据业务调整情况将本协议项下的权利和义务部分或全部转移给甲方指定的第三方，但须提前7日通知乙方，乙方应当在甲方通知的时间内与甲方指定的第三方签订补充协议，明确由甲方指定的第三方承继甲方在本协议项下的全部权利义务。
        </p>
        <p className="text">
          21. 甲方有权利对乙方上传商品进行审核，通过审核后方能发布与销售。
        </p>
        <h2>第六条 乙方的权利与义务</h2>
        <p className="text">
          1.
          乙方产品在平台电商业务经营范围以乙方营业执照载明范围及与甲方相关协议文件约定并经甲方审核同意范围为准
          ，除此之外不得经营其他品类产品或制作、发布其他与经营范围无关的内容。
        </p>
        <p className="text">
          2.
          乙方开展电商销售时，乙方负责使其销售主播人员及其他直播人员认真学习平台规则规范、参与甲方组织的培训及考试，经过甲方主播认证平台培训、认证，通过甲方大屏直播电商主播认证后，方可开展直播业务。
        </p>
        <p className="text">
          3.
          乙方应当将销售主播人员及其他直播人员的账号等信息提前向甲方报送，经“中广直播间”平台确认后方可开展直播销售。乙方保证就上报及店铺绑定的直播人员账号信息，已经取得了账号主体的授权；乙方与直播人员就商品的直播，如有费用或报酬，由乙方与直播人员自行沟通协商，甲方、甲方合作方、关联方或“中广直播间”平台不向直播人员支付任何费用。
        </p>
        <p className="text">
          4.
          乙方负责提供拥有合法授权、符合法律规定、“中广直播间”平台规则、甲方监播管理要求的购物视听内容，运营商品销售、客服、配送和售后、为消费者开具发票等商品销售工作。
        </p>
        <p className="text">
          5.
          乙方应按照国家相关法律、法规对其所提供的电商内容进行审查，不得提供任何有违国家法律法规的、公序良俗、国家在电视端禁止或限制的、侵犯他人知识产权或其它合法权益的、“中广直播间”平台规则、公告、通知或各平台与乙方单独签署的协议中已明确说明不适合在“中广直播间”平台上销售及/或提供的内容。甲方有权对其店铺中出现的违反国家有关法律、法规规定及平台规则的信息予以立即删除。
        </p>
        <p className="text">
          6.
          乙方对其所提供的所有内容、产品承担所有责任，包括但不限于知识产权、产品质量责任等。因乙方所销售商品引起的争议和/或纠纷（包括但不限于消费者对商品真伪的质疑、投诉、起诉、警告等），乙方应当出具相关商品证明文件，负责出面处理争议和/或纠纷，甲方应予以配合。
        </p>
        <p className="text">
          7.
          为最大程度保障消费者权益，满足消费者对商品的质量要求，乙方有义务对其在“中广直播间”平台销售的每款商品按照国家标准、行业标准及甲方发布的各品类商品要求进行质量控制（包括且不仅限于商品法律法规符合性、商品安全性、商品功能材质与描述符合性、商品标识标示、商品外观、商品包装等），并依照国家法律法规提供售后三包服务。甲方有权根据市场反馈委托第三方质检机构进行不定期商品抽检（检测项目包括且不仅限于乙方销售商品的性能、质量、材料成分、是否符合国家法律法规要求等各方面），或要求乙方对甲方指定商品提供进货凭证、出厂检验报告或者第三方质检机构出具的检测报告等相关商品及批次的质量合格证明文件，对此乙方应当予以配合。
        </p>
        <p className="text">
          8.
          如果乙方所销售商品抽检不合格或无法向甲方提供相关商品及批次质量合格的证明文件，甲方有权根据平台规范及标准，并且依据问题的严重程度对乙方进行处罚及提出相应的限期整改要求。乙方必须配合限期整改及根据甲方的要求进行指定商品的第三方检测，并完全承担因此产生的所有费用。乙方拒绝执行的，甲方有权采取相关措施，包括且不仅限于停止乙方服务、终止本协议、要求乙方承担违约责任、损害赔偿等措施。
        </p>
        <p className="text">
          9.
          如果因乙方商品质量问题而导致甲方产生损失（包括且不仅限于经济或声誉上的损失），甲方有权要求乙方赔偿因此而产生的所有费用，并保留进一步追究乙方相应责任的权利。
        </p>
        <p className="text">
          10.
          如因乙方产品、服务存在违法、违规等情况导致甲方受到到国家相关监管部门的调查、问询等时，甲方有权要求乙方向监管机关提供相关资料，责令商家予以整改、执行监管意见等；乙方怠于执行或迟延执行时，甲方有权采取包括但不限于删除或调整乙方发布的信息、限制、封停乙方在“中广直播间”平台账号权限等，由此产生的全部法律责任由乙方自行承担。
        </p>
        <p className="text">
          11.
          乙方作为乙方产品销售者，其店铺由乙方自行维护，包括但不限于商品介绍、价格、规格等信息的上传、修改、展示等。乙方店铺内商品的经营由乙方以其自身名义开展，并负责用户的咨询答复、商品的推广宣传、商品的促销等事宜。
          甲方不参与乙方店铺的实际经营活动中。
        </p>
        <p className="text">
          12.
          乙方须保证直播期间产生的所有订单在48小时内发货，平均揽收时长不超过48小时，乙方须负责库存管控、订单发货、退换货及客户维护等事宜，保证库存充足。
        </p>
        <p className="text">
          13.
          乙方承诺，“中广直播间”平台上销售的全部商品，除法律法规另有规定外，应当适用七天无理由退货的规定。一旦消费者纠纷商家处理无果，消费者投诉至平台，则“中广直播间”平台有权介入处理，如经“中广直播间”平台判定适用七天无理由退货的规定，则乙方应当接受平台的处理结果，对相应商品适用七天无理由退货。
        </p>
        <p className="text">
          14.
          如因乙方销售的商品、发布的信息或提供的服务问题而引发第三人（包括但不限于客户、乙方经营行为的受侵害者等）对甲方及/或“中广直播间”平台的投诉、诉讼以及引发的国家监管部门查处的，由乙方处理，甲方有权将乙方的主体身份资料、注册资料、联系方式及交易信息等资料提供给投诉方或其它相关部门，乙方应承担因此而产生的全部法律责任，如因此而给甲方及/或“中广直播间”平台造成损失的，甲方有权要求乙方赔偿甲方及“中广直播间”平台相关主体的全部损失。
        </p>
        <p className="text">15. 乙方保证乙方的直播店铺同时满足下列标准：</p>
        <p className="text-4">
          (1) 客户投诉率低于0.05%（投诉率计算方式为：投诉件数/总的销售量）；
        </p>
        <p className="text-4">
          (2)
          因产品质量问题退款的比率（即“品退率”）应低于0.5%（品退率计算方式：因产品质量问题退款的件数/总的销售量）；
        </p>
        <p className="text-4">
          (3) 差评率（计算方式为：差评条数/店铺总的评论条数）低于5%。
        </p>
        <p className="text-4">
          如有任何一项及/或几项不满足前述比率，则乙方应在接到甲方通知后15天内提升相关指标，若乙方在甲方指定的时间内仍未满足前述比率，则甲方有权单方关闭乙方直播间而无需事先通知乙方。
        </p>
        <p className="text">
          16.
          乙方承诺负责本协议有效期内乙方店铺客服服务，且保证售前售后客服能做到及时回复，保证服务期间不出现因服务不周到而导致的违规或侵权等问题，如因此产生任何问题的，乙方须承担所有责任，乙方须全额赔偿由此给甲方或第三方造成的损失。
        </p>
        <p className="text">
          17.
          乙方保证在购物内容中不作出任何有损甲方、甲方关联企业权益等名誉等行为，不作出任何违背法律法规、违背社会公序良俗、对产品夸大宣传的行为。
        </p>
        <p className="text">
          18.
          乙方保证不侵犯用户隐私，保证对其在合作过程中所知悉的用户等主体的个人信息、商品信息、销售数据等信息进行严格保密，绝不私自使用或向其他任何第三方公开、透漏该些信息。
        </p>
        <p className="text">
          19.
          乙方有权向甲方有偿采购电商直播间导流流量、排序位置及推送方式。乙方同时可参与“中广直播间”平台相关活动及享受其他有偿服务。其他有偿服务包括但不限于广告推广服务、平台推广位、直播间排序服务等。乙方如需使用该等有偿服务的，应以乙方自身名义另行与甲方洽谈并签署协议。
        </p>
        <p className="text">
          20.
          乙方的经营行为受消费者的监督，消费者可以通过“中广直播间”平台评价功能对乙方的商品和服务进行评价，该评价将以分值的形式体现乙方商品及其服务的优劣；消费者也可以通过向甲方、监管机关、媒体等进行投诉进行监督，甲方知悉该投诉后，有权介入调查。
        </p>
        <p className="text">
          21.
          乙方不得在“中广直播间”平台发布任何吸引用户到其他平台或乙方自身网络销售平台或渠道进行交易的信息，也不得在配送包裹中夹带此类吸引用户的信息。为保证乙方的经营行为受“中广直播间”平台监管，乙方在平台的经营活动中，不得以任何名义和方式展现任何乙方单位或个人的电话号、电子邮件、二维码以及包括但不限于微信号、QQ号、微博账号等网络社交程序账号及其他联系方式。乙方仅能在平台系统内进行交易，不得进行站外导流（除使用甲方提供的站外导流工具外）。
        </p>
        <p className="text">
          22.
          乙方应承诺已取得了所销售商品的品牌方的授权，包括但不限于与商品及品牌代言人（如有）的授权；如乙方为线下商场，乙方所有在线销售商品的品牌，应当确保是乙方商场内在售的商品品牌；乙方不得销售非商场内及未经授权的品牌商品。
        </p>
        <h2>第七条 保证金</h2>
        <p className="text">
          1.
          乙方在“中广直播间”开设店铺应缴纳保证金，保证金标准根据商品类别不同而不同，具体以附件一为准。乙方应在每个店铺开设前足额缴纳保证金。若乙方违反甲方“中广直播间”平台规范，甲方有权扣罚保证金，扣罚后乙方应于甲方通知之日起7日内补足。在合同结束后如保证金有剩余，由甲方在15个工作日内无息退还乙方。
        </p>
        <p className="text">
          2.
          乙方在平台中的经营行为违法、违反本协议约定、违反平台相关规则或侵犯第三方权利的，甲方有权根据本协议约定及平台规则规定没收乙方全部或部分保证金，有权直接从乙方保证金中扣除应当由乙方承担的违约金、罚金、及相关方上述损失，乙方保证金不足以支付上述违约金、罚金及损失赔偿金的，乙方应当继续赔偿。
        </p>
        <h2>第八条 技术服务费</h2>
        <p className="text">
          1.
          对于甲方为乙方提供的技术服务，乙方应支付技术服务费。乙方应按照通过“中广直播间”平台所产生的结转销售额的一定百分比（即“佣金比例”）向甲方支付技术服务费，佣金比例根据商品类目不同而不同，具体以附件一为准；同时，甲方有权根据行业发展情况，提前7个工作日书面通知乙方，调整部分或全部类目的佣金比例。
        </p>
        <p className="text">2. 结算周期：每笔销售订单完成后实时分账。</p>
        <p className="text">
          3.
          每月10日前，双方核对上月度的结转销售额账单，核对无误后甲方为乙方开具甲方上月度技术服务费等额的增值税专用发票。
        </p>
        <p className="text">4. 乙方开票信息如下：【 】</p>
        <h2>第九条 知识产权条款</h2>
        <p className="text">
          1.
          甲方对其制作、提供的宣传视频内容拥有完全的知识产权，未经甲方事先书面同意，乙方不得擅自使用或许可他人使用该等购物视频、照片及其任何内容。乙方保证尊重甲方的商标、商号权，未经甲方事先书面同意，乙方不得以任何形式在其商品、宣传或其他方面使用甲方的商标、商号。
        </p>
        <p className="text">
          2.
          甲方不得自行制作含有乙方商品的购物节目或视频，甲方可根据自身节目需要重新剪辑视频资料。
        </p>
        <p className="text">
          3.
          甲方所提供的商家运营平台等系统知识产权归甲方所有，未经甲方同意，乙方不得提供给第三方使用。
        </p>
        <p className="text">
          4.
          对于乙方提供、发布及在使用“中广直播间”平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，在法律规定的保护期限内乙方免费授予甲方及其关联公司获得全球排他的许可使用权利及转授权，包括但不限于信息网络传播权、品牌代言人肖像权等权利，并授权甲方可以自身名义对第三方侵权行为取证及提起诉讼。乙方同意甲方及其关联公司存储、使用、复制、修订、编辑、发布、展示、翻译、分发乙方的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。
        </p>
        <h2>第十条 保密条款</h2>
        <p className="text">
          1.
          双方保证对其在本合作中知悉的（不论是对方提供的还是偶然获得的）任何对方的商业机密、技术秘密、经营管理信息及其它信息和资料（书面或口头形式），以及双方为履行本协议而向对方披露的文件、资料和经营、管理、生产工艺、流程、文件，负有严格的保密义务。非经对方书面同意，任何一方不得向任何第三方透露该等保密信息。
        </p>
        <p className="text">
          2.
          如一方提出要求，任何一方均应将载有提出要求方所属的保密信息的文件、资料或软件，归还给提出要求的一方，或予以销毁，或进行其他处置，并且不得继续使用该保密信息。
        </p>
        <p className="text">
          3.
          在本协议终止之后，各方在本协议项下的保密义务并不随之终止，各方仍需遵守本协议之保密条款，履行保密义务，直到另一方同意其解除此项义务，或事实上不会因违反本协议的保密条款而给另一方造成任何形式的损害时止。
        </p>
        <h2>第十一条 合同有效期限</h2>
        <p className="text">
          本协议自双方盖章后生效，自【 】年【 】月【 】日起至【 】年【 】月【
          】日止。合作期届满前一个月，双方可就续签协议事宜进行协商，双方未协商续签的，本协议到期即终止。合同期满，乙方仍有义务完成合同履行期间产生与销售有关的附随义务。
        </p>
        <h2>第十二条 违约责任</h2>
        <p className="text">
          1.
          任何一方违反本协议项下的任何约定而给对方造成损失的，均应赔偿对方由此所遭受的直接及间接经济损失，赔偿损失的范围包括但不限于诉讼费、律师费、公证费、保全费、司法及行政处罚、守约方向第三方支付的损害赔偿、守约方为维权而产生的交通食宿费等。
        </p>
        <p className="text">
          2.
          乙方通过“中广直播间”平台推广或销售的所有商品，必须符合法律法规等相关规定及“中广直播间”平台规则等甲方要求的运营管理规范，否则甲方有权对乙方进行处罚，处罚方式包括但不限于：商品立即下架、要求乙方停播整改、没收乙方保证金、限制或暂停乙方自主提现货款功能、暂停乙方特定店铺服务功能、终止本协议、将乙方从“中广直播间”平台清退等。且乙方有权依法将违规情形和处罚情况向相应主管部门报送。
        </p>
        <p className="text">
          3.
          乙方向甲方提供虚假、失效的证明文件或其他相关证明，在“中广直播间”平台发布错误、虚假、违法及不良信息，将“中广直播间”平台用户吸引到“中广直播间”平台以外的平台或场所进行交易或绕开甲方指定付款方式进行交易，非法获取“甲方”系统数据，利用“中广直播间”平台谋取不正当利益或从事非法活动，或进行其他违法违规或违反本协议约定、平台规则的行为，甲方有权对乙方采取商品下架、封禁、没收乙方保证金、限制或暂停乙方自主提现商品销售价款功能、暂停乙方特定店铺服务功能、将乙方从“中广直播间”平台清退等措施，给甲方及/或“中广直播间”平台造成任何损失的（损失包括但不限于诉讼费、律师费、赔偿、补偿、行政机关处罚、差旅费等），乙方同意甲方自乙方缴纳的保证金中直接予以扣除，或指示第三方支付机构依据本协议约定直接扣划相应金额的商品销售价款。
        </p>
        <p className="text">
          4.
          若由于乙方通过“中广直播间”平台提供的合作业务视听内容、商品购物导致对任何最终用户/第三方的侵权或违约所引起的任何索赔，均应由乙方独自并完全承担责任，概与甲方无涉，给甲方造成损失的，乙方还应赔偿甲方的全部损失。
        </p>
        <p className="text">
          5.
          若由于甲方提供的合作业务系统的系统软件和客户端软件导致对任何第三方的侵权或违约所引起的任何索赔，均应由甲方独自并完全承担责任。但如果因乙方不适当使用或超出合同约定目的使用导致对任何第三方的侵权或违约所引起的任何索赔，则由乙方承担相应责任。
        </p>
        <p className="text">
          6.
          乙方承诺不在“中广直播间”平台销售假冒伪劣商品或水货（通过走私等非法方式进口的货物）、旧货、不合格产品等，否则甲方有权按“中广直播间”平台规范处理。同时甲方有权对乙方采取暂停向乙方提供服务、暂时关闭乙方后台管理账户、限制乙方自主提现商品销售价款功能。违约金不足以弥补甲方损失的，乙方还应全部赔偿（包括但不限于甲方对客户的赔偿、补偿、行政部门的处罚、律师费、诉讼费、鉴定费、差旅费等）。上述违约金的支付并不影响甲方依本协议及附件和/或补充协议（若有）的约定扣除相应的保证金。
        </p>
        <p className="text">
          7.
          如乙方的行为使甲方及/或其关联公司、遭受第三人主张权利，甲方及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向乙方追偿。
        </p>
        <h2>第十三条 有限责任及免责</h2>
        <p className="text">
          1.
          不论在何种情况下，甲方均不对由于电力、罢工（含内部罢工或劳工骚乱）、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为等不可抗力，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
        </p>
        <p className="text">
          2.
          鉴于互联网及电子商务所具有之特殊性质，甲方的免责事由亦包括但不限于下列任何影响网络正常经营之情形：（1）大规模黑客攻击、计算机病毒侵入或发作；（2）计算机系统遭到破坏、瘫痪或无法正常使用而导致甲方不能履行本服务协议下义务；（3）电信部门技术调整导致之重大影响；（4）因政府管制而造成的暂时性关闭等；（5）
          其他非甲方过错造成的情形。
        </p>
        <p className="text">
          3.
          法律地位声明：甲方为向乙方及甲方其他用户进行网上交易活动提供交易平台的平台服务提供商，并非乙方与通过“中广直播间”平台购买乙方商品的甲方其他用户之间交易行为的参与方，甲方不对乙方及参与交易的甲方其他用户的任何口头、书面陈述或承诺，发布的信息及交易行为的真实性、合法性、准确性、及时性、有效性等作任何明示或暗示的保证，亦不承担任何法律责任。若因乙方与参与交易的甲方其他用户之间的交易行为引起的任何法律纠纷，包括但不限于投诉、起诉、举报及税赋等，均由参与交易的双方解决，与甲方及/或“中广直播间”平台无关。但乙方怠于履行义务时或本协议、平台规则另有约定时，甲方有权介入乙方与甲方其他用户间的争议，依据一般人的认知程度对该争议进行判断和处置，乙方应当予以执行。
        </p>
        <h2>第十四条 不可抗力</h2>
        <p className="text">
          1.
          本协议任一方因不可抗力（不可抗力：由于地震、台风、洪水、火灾、战争、罢工、政府干预以及其他不能预见并且对其发生后果不能防止或避免的不可抗力）而不能或延迟履行本协议项下任何义务给对方造成的任何损失不承担相关责任。
        </p>
        <p className="text">
          2.
          若由于不可抗力致使一方未能全部或部分履行本协议，应立即以传真或其他更为迅速的方式通知另一方上述不可抗力事件的详细情况，并于事故发生后十四天内向另一方提供有关机关出具的不可抗力的证明。在上述情况下，该方仍有义务采取一切可能措施从速交货。如果事故持续超过六个月，任何一方有权撤销本协议。该方在发生不可抗力情况时有通知义务以及从速履行义务，如该方有未尽上述两义务之情形，视为未有不可抗力情形发生，按延迟履行义务或不能履行义务的违约行为或根本违约处理。
        </p>
        <h2>第十五条 协议的变更和解除</h2>
        <p className="text">
          1. 双方协商一致可变更协议，变更协议应签署书面补充协议。
        </p>
        <p className="text">
          2.
          任何一方欲终止协议，需提前15日以书面形式通知对方，甲、乙双方协商一致，可以解除本协议。
        </p>
        <p className="text">
          3.
          如果甲乙双方任何一方违反本协议约定的，且在另一方提出书面通知30日内没有纠正，另一方有权解除本协议并追究违约方责任。
        </p>
        <p className="text">4. 乙方有下述情形时，甲方可单方解除本协议：</p>
        <p className="text-4">
          (1) 乙方未正常经营，在服务期内累计达到 45（四十五）日或者连续达到
          30（三十）日的；
        </p>
        <p className="text-4">
          (2)
          乙方违反本协议约定或者“中广直播间”平台规则规定的相关义务，经甲方通知后拒不改正的；
        </p>
        <p className="text-4">
          (3)
          乙方商品因质量问题导致行政执法部门介入处理（包括但不限于消费者投诉举报至监管部门、行政执法部门例行执法检查、因乙方重大违法或负面舆情引发的行政执法部门检查等），并因此导致甲方、甲方合作方或关联方、“中广直播间”平台负面影响或是遭受行政机关处罚的；
        </p>
        <p className="text-4">(4) 本协议约定的其他甲方可单方解除本协议的。</p>
        <p className="text">5. 协议终止后后续事项的处理：</p>
        <p className="text-4">
          (1)
          自本协议终止之日起，甲方有权关闭乙方“中广直播间”平台用户权限，并对乙方商品全部下架，乙方将无法再通过该账户对店铺进行任何形式的操作，同时甲方无义务在甲方网站继续显示任何乙方商品信息。
        </p>
        <p className="text-4">
          (2)
          本协议终止后，甲方有权保留乙方的注册信息及交易行为记录等数据，但甲方没有为乙方保留这些数据的义务，亦不承担在协议终止后向乙方或第三方转发任何未阅读或未发送的信息的义务，也不就协议终止向乙方或任何第三方承担责任。法律另有规定的除外。
        </p>
        <p className="text-4">
          (3)
          自本协议终止之日后，甲乙双方进行退场清算，包括但不限于财务对账及在途货物的处理。在途货物指双方合作终止前，客户已购买但尚未交付的商品，对于此类商品，乙方仍应按照合作终止前的流程交付并结算。
        </p>
        <p className="text-4">
          (4)
          本协议终止，并不免除乙方依据本协议应向客户承担的相关服务及产品保证责任；如在甲乙双方合作终止后，因乙方商品质量问题或售后服务问题而导致甲方或其他第三方人身或财产损失的，乙方仍应独立承担全部责任。
        </p>
        <h2>第十六条 通知及送达</h2>
        <p className="text">
          1.
          双方发出的通知，其中以电子的方式发出的书面通知，包括但不限于在“中广直播间”平台公告、向对方提供的联系电话发送手机短信、向对方提供的电子邮件地址发送电子邮件、向对方的社交账号发送信息、系统消息以及站内信信息，
          在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
        </p>
        <p className="text">
          2.
          对于在“中广直播间”平台上因交易活动引起的任何纠纷，乙方同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件或微信等现代通讯方式或邮寄方式向乙方送达法律文书（包括但不限于诉讼文书）。乙方指定接收法律文书的手机号码、电子邮箱或账号等联系方式为乙方在“中广直播间”平台注册、更新时提供的手机号码、电子邮箱联系方式以及在注册甲方用户时生成账号，司法机关向上述联系方式发出法律文书即视为送达。乙方指定的邮寄地址为乙方的法定联系地址或乙方提供的有效联系地址。乙方同意司法机关可采取以上一种或多种送达方式向乙方达法律文书，司法机关采取多种方式向乙方送达法律文书，送达时间以上述送达方式中最先送达的为准。乙方同意上述送达方式适用于各个司法程序阶段。
        </p>
        <h2>第十七条 争议解决</h2>
        <p className="text">
          1.
          由于本协议有关事宜引起争议，协议双方应首先力求以友好协商的方式予以解决。如通过协商不能达成一致时，双方同意将争议提交甲方所在地人民法院诉讼解决。
        </p>
        <p className="text">
          2.
          在争议的处理过程中，除正在进行处理的部分外，本协议的其它部分须继续执行。
        </p>
        <p className="text">
          3. 本协议的订立、执行、解释及争议的解决均适用中国法律。
        </p>
        <h2>第十八条 其他事项</h2>
        <p className="text">
          1.
          本协议未尽事宜由甲、乙双方友好协商后签订书面补充协议，补充协议与本协议不一致的，以补充协议为准。
        </p>
        <p className="text">
          2. 本协议附件是本协议不可分割的一部分，与本协议具有同等法律效力。
        </p>
        <p className="text">
          3. 本协议一式四份，甲、乙双方各持两份，每份具有同等法律效力。
        </p>
        <p className="text">（本行以下无正文）</p>
        <p className="text-4"></p>

        <table>
          <tr>
            <td>甲方（盖章）：</td>
            <td>乙方（盖章）：</td>
          </tr>
          <tr>
            <td>国广鑫华科技（北京）有限公司</td>
            <td></td>
          </tr>
          <tr>
            <td>法定代表人/授权代表（签字）：</td>
            <td>法定代表人/授权代表（签字）：</td>
          </tr>
          <tr>
            <td>日期： 年 月 日</td>
            <td>日期： 年 月 日</td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default MerChant;
