import React from "react";
import { Image } from "antd";
import AutoPlayCarousel from "./AutoPlay";
import { playletPrefix } from "@/utils/image";
const data = new Array(9).fill(0).map((item, index) => {
  return { num: index };
});
const itemStyle = {
  border: "1px solid #ccc",
  background: "#fff",
  color: "red",
  marginRight: "15px",
};
function Carousel() {
  const showNum = 5;
  const Item = (item) => {
    return (
      // <img
      //   style={itemStyle}
      //   src={playletPrefix + item.num + ".jpg"}
      //   width={180}
      //   alt=""
      // />
      <Image
        style={itemStyle}
        src={playletPrefix + item.num + ".jpg"}
        width={180}
        preview="预览"
        alt=""
      />
    );
  };
  return (
    <AutoPlayCarousel
      Item={Item}
      containerWidth={window.innerWidth * 0.83}
      showNum={showNum}
      speed={8}
      data={data}
      hoverStop={true}
    />
  );
}

export default Carousel;
