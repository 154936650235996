import React from "react";
import "./index.css";
const Privacy = () => {
  return (
    <>
      <div className="privacy">
        <h1 style={{ textAlign: "center", fontSize: 40, marginBottom: 50 }}>
          隐私政策
        </h1>
        <p>1、引言</p>
        <p>2、我们可能收集的信息</p>
        <p>3、我们可能如何使用信息</p>
        <p>4、您如何访问和控制自己的个人信息</p>
        <p>5、我们可能分享的信息</p>
        <p>6、信息安全</p>
        <p>7、我们可能如何收集信息</p>
        <p>8、我们可能向您发送的邮件和信息</p>
        <p>9、隐私政策的适用范围</p>
        <p style={{ marginBottom: 100 }}>10、变更</p>
        <h2>1、引言</h2>
        <p className="text">
          国广鑫华科技（北京）有限公司在向用户提供与“中广直播间”有关的服务的同时，亦重视用户的隐私。您在享受我们提供的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，在享受我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的中广直播间服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您的理解。
        </p>
        <p style={{ fontSize: 18, fontWeight: 800 }}>
          您使用或继续使用“中广直播间”视频直播客户端及其系统，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。
        </p>
        <h2>2、我们可能收集的信息</h2>
        <p className="text">
          我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
        </p>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          您提供的信息
        </p>
        <p className="text-4">
          ●
          您在注册账户或使用“中广直播间”视频直播客户端及其系统时，向我们提供的相关个人信息，例如真实姓名、身份证号码、电话号码、电子邮箱等；
        </p>
        <p className="text-4">
          ● 您使用“中广直播间”视频直播客户端及其系统时所储存的信息。
        </p>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          我们获取的您的信息
        </p>
        <p className="text-4">
          您使用“中广直播间”视频直播客户端及其系统时我们可能收集如下信息：
        </p>
        <p className="text-4">
          ●
          日志信息，指您使用我们的服务时，系统可能通过cookies或其他方式自动采集的技术信息，包括：
        </p>
        <p className="text-4">
          ●
          设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码
        </p>
        <p className="text-4">
          ●
          在使用“中广直播间”视频直播客户端及其系统时搜索或浏览的信息，例如您使用的搜索词语以及您在使用“中广直播间”视频直播客户端及其系统时浏览或要求提供的其他信息和内容详情。
        </p>
        <h2>3、我们可能如何使用信息</h2>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：
        </p>
        <p className="text-4">● 向您提供服务</p>
        <p className="text-4">
          ●
          在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性
        </p>
        <p className="text-4">● 帮助我们设计新服务，改善我们现有服务；</p>
        <p className="text-4">
          ●
          使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户做出其他方面的回应；
        </p>
        <p className="text-4">● 软件认证或管理软件升级；</p>
        <p className="text-4">● 让您参与有关我们产品和服务的调查。</p>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
        </p>
        <h2>4、您如何访问和控制自己的个人信息</h2>
        <p className="text">
          我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。
        </p>
        <h2>5、我们可能分享的信息</h2>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          除以下情形外，未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息：
        </p>
        <p className="text-4">
          ●
          我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、分享（他们可能并非位于您所在的法域），用作下列用途：
        </p>
        <p className="text-4">● 向您提供我们的服务；</p>
        <p className="text-4">● 实现“我们可能如何使用信息”部分所述目的；</p>
        <p className="text-4">
          ● 履行我们在《用户服务协议》或本《隐私政策》中的义务和行使我们的权利；
        </p>
        <p className="text-4">● 理解、维护和改善我们的服务。</p>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。
        </p>
        <p className="text-4">
          ●
          随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您
        </p>
        <p className="text-4">
          ● 我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：
        </p>
        <p className="text-4">● 遵守适用的法律法规；</p>
        <p className="text-4">● 遵守法院命令或其他法律程序的规定；</p>
        <p className="text-4">● 遵守相关政府机关的要求；</p>
        <p className="text-4">
          ●
          为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
        </p>
        <h2>6、信息安全</h2>
        <p className="text">
          我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
        </p>
        <h2>7、我们可能如何收集信息</h2>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          我们或我们的第三方合作伙伴，可能通过cookies收集和使用您的信息，并将该等信息储存为日志信息。
        </p>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          我们使用自己的cookies，目的是为您提供更个性化的用户体验和服务，并用于以下用途：
        </p>
        <p className="text-4">
          ●
          记住您的身份。例如：cookies有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；
        </p>
        <p className="text-4">
          ●
          分析您使用我们服务的情况。例如，我们可利用cookies来了解您使用我们的服务进行什么活动，或哪些网页或服务最受您的欢迎；
        </p>
        <p className="text-4">
          我们为上述目的使用cookies的同时，可能将通过cookies收集的非个人身份信息，经统计加工后提供给其他合作伙伴，用于分析用户如何使用我们的服务。
        </p>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          我们的产品和服务上可能会有广告商或其他合作方放置的cookies。这些cookies可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方cookies收集和使用该等信息，不受本《隐私政策》约束，而是受相关使用者的隐私政策约束，我们不对第三方的cookies承担责任。
        </p>
        <p className="text-4" style={{ fontSize: 18, fontWeight: 800 }}>
          您可以通过浏览器设置拒绝或管理cookies。但请注意，如果停用cookies，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。
        </p>
        <h2>8、我们可能向您发送的邮件和信息</h2>
        <p className="text-4">邮件和信息推送</p>
        <p className="text-4">
          您在享受我们的服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。
          与服务有关的公告
        </p>
        <p className="text-4">
          我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
        </p>
        <h2>9、隐私政策的适用范围</h2>
        <p className="text">
          除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定服务的隐私政策。
        </p>
        <p className="text">
          除本《隐私政策》另有规定外，本《隐私条款》所用词语将与《用户服务协议》所定义的词语具有相同的涵义。
        </p>
        <p className="text" style={{ fontSize: 18, fontWeight: 800 }}>
          请您注意，本《隐私政策》不适用于以下情况：
        </p>
        <p className="text-4">
          ● 通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息。
        </p>
        <h2>10、变更</h2>
        <p className="text">
          我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
        </p>
        <p className="text">
          国广鑫华科技（北京）有限公司拥有对《隐私政策》的最终解释权。《隐私政策》提及的部分服务，国广鑫华科技（北京）有限公司可能会暂未提供，国广鑫华科技（北京）有限公司有权根据业务情况决定提供服务的类型、范围、期限等。
        </p>
      </div>
    </>
  );
};
export default Privacy;
