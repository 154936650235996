import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Layout, Anchor } from "antd";
import Title from "@/components/Title";
import { Mobile } from "@/utils/window";

import styles from "./index.module.css";
import "./index.css";

import { desc, course, coursecut } from "@/utils/image";

const { Content } = Layout;
const Info = () => {
  const introRef = useRef(null);
  const descRef = useRef(null);
  const cultureRef = useRef(null);
  const courseRef = useRef(null);
  const isMobile = Mobile();

  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      switch (location.hash) {
        case "#intro":
          introRef.current.scrollIntoView(true);
          break;
        case "#desc":
          descRef.current.scrollIntoView(true);
          break;
        case "#culture":
          cultureRef.current.scrollIntoView(true);
          break;
        case "#course":
          courseRef.current.scrollIntoView(true);
          break;
        default:
          introRef.current.scrollIntoView(true);
      }
    }
  }, [location]);

  return (
    <>
      <div className={isMobile ? "" : styles.main}>
        <Content>
          <Anchor
            className={styles.anchor}
            direction="horizontal"
            targetOffset={window.innerHeight / 12}
            items={[
              {
                key: "intro",
                href: "#intro",
                title: "简介",
              },
              {
                key: "desc",
                href: "#desc",
                title: "公司简介",
              },
              {
                key: "culture",
                href: "#culture",
                title: "企业文化",
              },
              {
                key: "course",
                href: "#course",
                title: "发展历程",
              },
            ]}
          />
          <Row className={styles.intro}>
            <div id="intro" ref={introRef} className={styles.layout}>
              <Col span={24}>
                <h1 className={styles.introTitle}>公司简介</h1>
              </Col>
              <Col span={24}>
                <span className={styles.introSubTitle}>品质优选，严格把控</span>
              </Col>
            </div>
          </Row>
          <Row>
            <div id="desc" ref={descRef} className={styles.layout}>
              <Col span={24}>
                <Title
                  isMobile={isMobile}
                  titleColor="black"
                  underLineColor="red"
                >
                  公司简介
                </Title>
              </Col>
              <Col span={24}>
                <img className={styles.descImg} src={desc} alt="" />
              </Col>
              <Col span={24}>
                <div className={styles.descContent}>
                  <p className="text">
                    国广鑫华科技（北京）有限公司是第一家经国家广播电视总局许可、在互联网电视平台开展直播电商业务的公司。公司致力于互联网电视电商直播业务“中广直播间”项目，该项目立足于互联网电视大屏的公信力，以及移动端小屏交互的便捷性，满足基于互联网电视相关视频直播业务的应用需求；借助技术平台，将快速通过直播与交互来集合用户、主播、商品等要素，实现各类直播电商视频服务，并快速汇聚行业论坛、行业峰会、政府直播、形象代言等各类视频直播。
                    <br />
                  </p>
                  <p className="text">
                    公司以国家广播电视总局批复意见中“两个封闭”为原则，以互联网电视平台为基础，通过引入互联网优秀直播内容资源、人才资源、电商资源，充分利用智能电视大屏的媒体优势对内容、交互、商业模式进行优化和强化。
                    <br />
                  </p>
                  <p className="text">
                    中广直播间项目以“CIBN开播”产品作为互联网电视大屏直播的流量入口和直播能力平台，打造业务和技术的总分两级体系架构，统一品牌、统一终端、统一平台、统一播控、分级运营。通过整合并汇聚各类直播内容来突出横屏模式的直播内容呈现；通过大小屏联动，大小屏同步呈现，充分利用大屏的视听优势，大屏做内容展现，小屏做转化，小屏主创作，优质主播和优质内容通过大屏播出，并构建大屏粉丝的互动生态。
                    <br />
                  </p>
                  <p className="text">
                    公司将以中广直播间项目为契机，在“大屏＋直播”的媒体内容类业务融合创新上，通过原创性思想和开拓性工作，克服困难，解决问题，积累新做法和新经验，将中广直播间项目发展成为互联网电视大屏生态的新增长点，成为互联网电视行业乃至整个广电行业大屏的旗舰项目，为媒体融合发展事业贡献国广鑫华力量。
                  </p>
                </div>
              </Col>
            </div>
          </Row>
          <Row className={styles.culture}>
            <div id="culture" ref={cultureRef} className={styles.layout}>
              <Col>
                <Title
                  isMobile={isMobile}
                  titleColor="white"
                  underLineColor="white"
                >
                  公司文化
                </Title>
              </Col>
              <Col span={24} className="text">
                <div className={styles.cultureContent}>
                  公司秉承守正创新、融合发展的理念，依托大股东中国广电的深厚背景，主动融入中国广电“圆心战略”，实现中国广电数字底座为公司大屏直播业务赋能，使公司大屏电商直播与有线电视网、信息通信网和互联网形成有效协同、互为场景、互为生态的应用体系，构建创新、高效、可管、可控的智能大屏直播媒体平台。
                </div>
              </Col>
            </div>
          </Row>
          <Row className={isMobile ? "" : styles.course}>
            <div id="course" ref={courseRef} className={styles.layout}>
              <Col span={24}>
                <Title
                  isMobile={isMobile}
                  titleColor="black"
                  underLineColor="red"
                >
                  发展历程
                </Title>
              </Col>
              <Col span={24} className={styles.marginBottom}>
                <Row>
                  <Col span={isMobile ? 24 : 14}>
                    <img className={styles.courseImg} src={course} alt="" />
                  </Col>
                  <Col
                    offset={isMobile ? 0 : 1}
                    span={isMobile ? 24 : 9}
                    className="text"
                  >
                    <span
                      className={isMobile ? "" : styles.courseContent}
                      style={isMobile ? {} : { width: 588, height: 321 }}
                    >
                      2018年6月 国广鑫华科技（北京）有限公司成立
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className={styles.marginBottom}>
                <Row>
                  <Col
                    offset={isMobile ? 0 : 1}
                    span={isMobile ? 24 : 9}
                    order={isMobile ? 1 : 2}
                    style={isMobile ? {} : { height: "100%" }}
                  >
                    <img className={styles.courseImg} src={coursecut} alt="" />
                  </Col>
                  <Col
                    style={isMobile ? {} : { width: 832, height: 200 }}
                    span={isMobile ? 24 : 14}
                    order={isMobile ? 2 : 1}
                    className={`text ${styles.courseContent}`}
                  >
                    <span>
                      2021年4月
                      国家广播电视总局批复开展“中广直播间”互联网电视电商直播业务资质
                    </span>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </Content>
      </div>
    </>
  );
};
export default Info;
