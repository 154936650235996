import React from "react";
import "./index.css";
const Terms = () => {
  return (
    <>
      <div className="terms">
        <h1 style={{ textAlign: "center", fontSize: 40 }}>用户服务协议</h1>
        <h2>1. 服务条款的接受与修改</h2>
        <p className="text">
          1.1 本协议是用户与国广鑫华科技（北京）有限公司（以下简称
          “国广鑫华”）之间签署的协议（以下简称
          “本协议”），本协议生效后，国广鑫华将严格依照本协议中的服务条款提供服务。本协议条款构成您（无论个人或者单位）使用国广鑫华所提供的服务及其衍生服务之先决条件，通过访问和/或使用国广鑫华网站、客户端或国广鑫华提供的其他服务，表示您接受并同意本协议的全部条款。如果您不愿意接受本协议的全部条款，您应不使用或主动取消国广鑫华提供的任何服务。
        </p>
        <p className="text">
          1.2
          本协议适用于国广鑫华提供的，并需要用户注册的全部网页服务、客户端服务及其他服务。　
        </p>
        <p className="text">
          1.3
          如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用相关服务。
        </p>
        <h2>2. 服务说明 </h2>
        <p className="text">2.1 国广鑫华向用户提供包括但不限于以下服务：</p>
        <p className="text-4">
          2.1.1
          国广鑫华网站，包含其他任何由国广鑫华直接所有或运营的网站（下称“国广鑫华网站”）。
        </p>
        <p className="text-4">
          2.1.2 国广鑫华直接所有或运营的视频直播客户端（下称“中广直播间”）。
        </p>
        <p className="text-4">
          2.1.3国广鑫华提供的其他技术和/或服务（下称“国广鑫华其他技术和服务”）。
        </p>
        <p className="text-4">
          以上服务统称为“国广鑫华服务”，国广鑫华服务所在的平台统称“国广鑫华平台”。
        </p>
        <p className="text">
          2.2
          国广鑫华服务，均仅限于用户在国广鑫华平台使用，任何以恶意破解等非法手段将国广鑫华服务与国广鑫华平台分离的行为，均不属于本协议中约定的国广鑫华服务。由此引起的一切法律后果由行为人负责，国广鑫华将依法追究行为人的法律责任。
        </p>
        <p className="text">
          2.3
          国广鑫华官方公布的方式为下载、注册、登陆、使用国广鑫华服务的唯一合法方式，用户通过其他任何渠道、途径、方式获取的国广鑫华服务（包括且不限于帐号、客户端下载等服务）均为非法取得，国广鑫华概不承认其效力，且一经发现国广鑫华有权立即做出删除、取消、封号、报警等处理，任何因此导致的一切不利后果均由用户自行承担。
        </p>
        <p className="text">
          2.4国广鑫华有权根据实际情况自行决定上述服务和产品（经合法渠道取得的实体产品所有权除外）的收回日期而无需通知用户，亦无需征得用户同意。
        </p>
        <p className="text">
          2.5
          国广鑫华有权在提前向用户公告（包括但不限于弹出页面公告、国广鑫华官方网站公告）后修改、替换、升级与国广鑫华服务相关的任何软件。如用户不接受国广鑫华服务相关软件的修改、替换、升级，请直接拒绝、停止、取消，否则视为用户接受国广鑫华相关软件的修改、替代、升级，同时该接受行为仍受本协议约束。
        </p>
        <h2>3. 使用规则 </h2>
        <p className="text">
          3.1
          用户在申请使用国广鑫华服务时，必须向国广鑫华提供完整、真实、准确、有效、最新的个人资料信息。如果上述信息发生变化，用户应及时更改。
        </p>
        <p className="text">
          3.2
          用户应妥善保管自己的帐号、密码(包括但不限于网站、客户端等国广鑫华提供的所有服务)，不得转让或出借予他人使用。当用户的帐号或密码遭到未经授权的使用时，用户应当立即通知国广鑫华，否则该行为即视为用户本人的行为。
        </p>
        <p className="text">
          3.3
          用户同意国广鑫华在提供服务的过程中以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在国广鑫华平台的任何位置上投放广告，在用户上传、传播的内容中投放广告）。
        </p>
        <p className="text">
          3.4
          用户在使用国广鑫华服务过程中，应遵守以下法律法规：用户同意遵守《中华人民共和国保守国家秘密法》《中华人民共和国著作权法》《中华人民共和国计算机信息系统安全保护条例》《计算机软件保护条例》《互联网电子公告服务管理规定》《信息网络传播权保护条例》等有关计算机及互联网规定的法律、法规。在任何情况下，国广鑫华一旦合理地认为用户的行为可能违反上述法律、法规，可以在任何时候，不经事先通知即中止或终止向该用户提供服务。
        </p>
        <p className="text">
          3.5 使用国广鑫华提供的商品或服务时，禁止用户从事以下行为：
        </p>
        <p className="text-4">
          3.5.1
          制作、上传、复制、传送、传播包含任何反对《中华人民共和国宪法》所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容，或其他令人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息的其他资料
        </p>
        <p className="text-4">3.5.2 以任何方式危害未成年人。</p>
        <p className="text-4">
          3.5.3
          冒充任何个人或机构，或以虚伪不实的方式欺骗或使他人误认为与任何个人或任何机构有关。
        </p>
        <p className="text-4">
          3.5.4
          伪造标题或以其他方式操控识别资料，冒用国广鑫华的名义，使他人误认为该内容为国广鑫华所传送
        </p>
        <p className="text-4">
          3.5.5
          将无权传送的内容（例如内部资料、机密资料）进行上载、张贴、发送电子邮件或以其他方式传送。
        </p>
        <p className="text-4">
          3.5.6
          将侵犯他人的专利权、商标权、著作权、商业秘密或其他专属权利之内容加以上载、张贴、发送电子邮件或以其他方式传送。
        </p>
        <p className="text-4">
          3.5.7
          将广告函件、促销资料、“垃圾邮件”等，加以上载、张贴、发送电子邮件或以其他方式传送。在国广鑫华明示许可的专区除外。
        </p>
        <p className="text-4">3.5.8 跟踪或以其他方式骚扰他人。</p>
        <p className="text-4">
          3.5.9
          将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送。
        </p>
        <p className="text-4">
          3.5.10
          干扰或破坏国广鑫华服务或与国广鑫华服务相连的服务器和网络，或不遵守本协议之相关约定，从事影响国广鑫华正常运营的行为。
        </p>
        <p className="text-4">
          3.5.11
          故意或过失违反任何相关的中国法律、法规、规章、条例等具有法律效力的规范。
        </p>
        <p className="text-4">
          3.5.12
          用户需保证其直播节目不得违反广电总局的相关规定，包括但不限于《国家广播电视总局关于加强网络秀场直播和电商直播管理的通知》（广电发〔2020〕78号）《关于加强网络直播规范管理工作的指导意见》（国信办发文〔2021〕3号）《网络直播营销管理办法（试行）》等，直播节目不得含有以下内容：违反《网络信息内容生态治理规定》（国家互联网信息办公室令
          第5号）第六条、第七条规定的；发布虚假或者引人误解的信息，欺骗、误导用户；营销假冒伪劣、侵犯知识产权或不符合保障人身、财产安全要求的商品；虚构或者篡改交易、关注度、浏览量、点赞量等数据流量造假；知道或应当知道他人存在违法违规或高风险行为，仍为其推广、引流；骚扰、诋毁、谩骂及恐吓他人，侵害他人合法权益；传销、诈骗、赌博、贩卖违禁品及管制物品等；其他违反国家法律法规和有关规定的行为
        </p>
        <p className="text-6">
          如用户提供的直播节目含有以上违反政策法律法规的信息或者内容的，由用户直接承担以上行为导致的一切不利后果；如因此给国广鑫华造成不利后果的，用户应负责消除影响，并且赔偿因此导致的一切损失
        </p>
        <p className="text-4">
          3.5.13
          用户不得通过任何渠道或媒体（包括但不限于自媒体等）发出“与国广鑫华合作”、“与国广鑫华共同出品”等任何携带“国广鑫华”或“CIBN互联网电视”或CIBN相关品牌的字样。如用户需宣传推广合作节目，用户只能在宣传中提及节目本身而不得提及与国广鑫华和“CIBN互联网电视”存在关联关系或者擅自以国广鑫华和“CIBN互联网电视”品牌的名义进行相关推广活动，凡是用户的发稿带有“国广鑫华”和“CIBN互联网电视”等字样的一切宣传稿件必须通过国广鑫华的书面同意和授权，否则因此给国广鑫华造成的一切损失用户应予以赔偿。
        </p>
        <p className="text">
          3.6
          用户使用国广鑫华服务在国广鑫华平台制作、上传、发布、传播的符号、文字、图片、音频、视频等内容的，应保证其内容不违反法律法规及其规范性文件，亦不侵犯任何第三方的合法权益，否则导致的一切不利后果均由用户自行承担，造成国广鑫华损失的，还应向国广鑫华承担赔偿责任。对于相关权利人因用户违反本款约定向国广鑫华主张权利的，国广鑫华有权不通知上传用户直接删除、下线、屏蔽相关争议内容。
        </p>
        <p className="text">
          3.7
          用户对其利用国广鑫华服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容的行为，独立完整地承担法律责任和经济赔偿责任；造成国广鑫华损失的，还应向国广鑫华承担赔偿责任，包括且不限于财产损害赔偿、名誉损害赔偿、律师费、交通费等因维权而产生的合理费用。同时：
        </p>
        <p className="text-4">
          3.7.1
          为了维护法律法规及相关规范性文件、保护他人的合法权益、维护国广鑫华的信誉及安全，国广鑫华有权直接删除用户利用国广鑫华服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容，而无需事先通知用户。
        </p>
        <p className="text-4">
          3.7.2
          国广鑫华有权视情节严重程度对该用户做出查封ID，暂时停止、永久禁止其利用国广鑫华服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容的权限
        </p>
        <p className="text-4">
          3.7.3 国广鑫华的后台记录有可能作为用户违反法律法规、违约、侵权的证据。
        </p>
        <p className="text">
          3.8
          除签订协议或者经过国广鑫华书面许可外，用户利用国广鑫华服务制作、上传、发布、传播的文字、符号、音频、视频等内容不得含有广告信息，包括且不限于广告的标题、简介等内容。
        </p>
        <p className="text">
          3.9
          任何用户均不得利用国广鑫华服务以倒卖、转手、置换、抵押有价交易等方式从事非法牟利行为。一经发现，国广鑫华有权严肃处理。
        </p>
        <p className="text">
          3.10
          用户应当保证，不会利用国广鑫华服务或其衍生服务侵犯他人的合法权益，禁止通过网络漏洞、恶意软件或其他非法手段窃取、盗用他人的国广鑫华帐号等；禁止发布侵犯他人合法权益的其他内容。否则，国广鑫华有权根据情节严重而对该用户进行封号等处理。
        </p>
        <p className="text">
          3.11
          用户可以向国广鑫华提出咨询和获得国广鑫华服务的合理技术支持，国广鑫华的此项义务不应超过国广鑫华的合理承受限度。
        </p>
        <p className="text">
          3.12
          用户可以非商业性地复制和散发国广鑫华服务及相关客户端（包括且不限于中广直播间客户端等），但在复制和散发过程中故意或过失造成他人损失的，由该用户自行承担一切不利后果；造成国广鑫华损失的，还应向国广鑫华承担赔偿责任。若用户利用国广鑫华服务及相关客户端等进行商业性的销售、复制、散发等商业行为（包括且不限于软件预装和捆绑），必须事先获得国广鑫华的书面授权和许可，但用户仍应为其在复制和散发过程中故意或过失造成他人损失的行为负责；造成国广鑫华损失的，还应向国广鑫华承担赔偿责任。
        </p>
        <h2>4. 知识产权 </h2>
        <p className="text">
          4.1
          受《中华人民共和国著作权法》《中华人民共和国专利法》《中华人民共和国商标法》及其他知识产权相关的法律法规的保护，国广鑫华提供的商品或服务所使用的软件、技术、商标、材料等的所有知识产权归国广鑫华或法定权利人所有和享有。“知识产权”包括在专利法、版权法、商标法、反不正当竞争法中等法律规定的任何和所有权利、任何和所有其它所有权以及其中的任何和所有应用、更新、扩展和恢复。
        </p>
        <p className="text">
          4.2
          未经国广鑫华书面许可和授权，任何用户不得修改、改编、翻译国广鑫华服务所使用的软件、技术、材料等，或者创作与之相关的派生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得其的源代码，否则由此引起的一切法律后果由用户负责，国广鑫华将依法追究违约方的法律责任。
        </p>
        <p className="text">
          4.3
          用户不得恶意修改、复制、传播国广鑫华服务所使用的软件、技术、材料等。否则，用户自行承担因此而造成对其他人的损害，若造成国广鑫华及/或关联公司损失的，应承担相应的法律责任。
        </p>
        <p className="text">
          4.4
          用户不得擅自删除、掩盖或更改国广鑫华的版权声明、商标或其它权利声明。国广鑫华平台所有设计图样以及其他图样、产品及服务名称，均为国广鑫华及/或其关联公司所有。任何人不得使用、复制或用作其他用途。
        </p>
        <p className="text">
          4.5
          经由国广鑫华服务传送的内容，未经国广鑫华明示授权许可，用户不得进行擅自修改、出租、散布、转载或衍生其他作品。国广鑫华对其自制内容和其他通过授权取得的独占内容享有完全知识产权，未经国广鑫华许可，任何单位和个人不得私自转载、传播和提供观看服务或者有其他侵犯国广鑫华知识产权的行为。否则，国广鑫华将追究侵权行为人的法律责任。
        </p>
        <p className="text">
          4.6
          国广鑫华所有和享有的知识产权，不因用户的任何使用行为而发生权利转移。
        </p>
        <h2>5. 第三方链接 </h2>
        <p className="text">
          5.1
          国广鑫华提供的服务可能会提供与其他互联网网站或资源渠道平台进行链接。除非另有声明，国广鑫华无法对第三方网站之服务进行控制，用户因使用或依赖上述网站或资源所产生的损失或损害，国广鑫华不承担任何责任。
        </p>
        <h2>6. 免责声明 </h2>
        <p className="text">
          6.1
          国广鑫华对于任何包含、经由或连接、下载或从任何通过有关本网络服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本服务上的广告、展示而购买、取得的任何产品、信息或资料不负保证责任。用户应仔细阅读，自行甄别，并自愿自行负担使用本服务的风险
        </p>
        <p className="text">6.2 国广鑫华对如下事项不做担保（包括但不限于）：</p>
        <p className="text-4">
          6.2.1
          国广鑫华提供的网站、客户端等软件虽然均已经过国广鑫华测试，但由于技术本身的局限性，国广鑫华不能保证与其他软硬件、系统完全兼容。如果出现不兼容的情况，用户可将情况反馈国广鑫华，以获得技术支持。如果无法解决问题，用户可以选择卸载、停止使用国广鑫华服务。
        </p>
        <p className="text-4">
          6.2.2
          使用国广鑫华服务涉及到互联网服务，可能会受到各个环节不稳定因素的影响。因不可抗力、黑客攻击、系统不稳定、网络中断、用户关机、通信线路等原因，均可能造成国广鑫华服务中断或不能满足用户要求的情况。国广鑫华不保证国广鑫华服务适合用户的使用要求。
        </p>
        <p className="text-4">
          6.2.3
          由于国广鑫华提供的客户端等软件可以通过网络途径下载、传播，因此对于从非国广鑫华指定官方站点下载、非国广鑫华指定途径获得的国广鑫华服务相关软件，国广鑫华无法保证其是否感染计算机病毒、是否隐藏有伪装的木马程序等黑客软件，也不承担用户由此遭受的一切直接或间接损害赔偿等法律责任。
        </p>
        <p className="text-4">
          6.2.4
          国广鑫华不做出任何与国广鑫华服务的安全性、可靠性、及时性和性能有关的担保。
        </p>
        <p className="text-4">
          6.2.5
          国广鑫华不保证其提供的任何产品、服务或其他材料符合用户的期望或满足用户的特殊要求。
        </p>
        <p className="text">
          6.3
          用户使用经由国广鑫华服务下载或取得的任何资料，其风险由用户自行负担，因该使用而导致用户电脑系统损坏或资料流失，用户应负完全责任。
        </p>
        <p className="text">
          6.4
          基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，国广鑫华不承担任何直接、间接、附带、衍生或惩罚性的赔偿：
        </p>
        <p className="text-4">6.4.1 国广鑫华服务使用或无法使用。</p>
        <p className="text-4">
          6.4.2 经由国广鑫华服务购买或取得的任何产品、资料或服务。
        </p>
        <p className="text-4">6.4.3 用户资料遭到未授权的使用或修改。</p>
        <p className="text-4">6.4.4 其他与国广鑫华服务相关的事宜</p>
        <p className="text">
          6.5
          用户应妥善保管自己的帐号和密码，加强密码安全性，谨防帐号泄露或被盗。因用户帐号被泄露或被盗而造成的任何损失，如帐号、帐号内财产等丢失、减少的，国广鑫华不承担任何赔偿责任。用户因电信和网通部门的通讯线路故障、网络或电脑故障、系统不稳定、不可抗力（如服务器当机）等非国广鑫华原因造成的帐号、帐号内财产等丢失、减少的，国广鑫华不承担任何赔偿责任
        </p>
        <p className="text">
          6.6
          用户因缺少身份认证或认证信息不真实而导致帐号、帐号内财产等丢失、减少而无法找回的，国广鑫华不承担任何法律责任。
        </p>
        <p className="text">
          6.7
          用户理解并同意自主选择免费下载和使用国广鑫华服务，风险自负，包括但不限于用户使用国广鑫华服务过程中的行为，以及因使用国广鑫华服务产生的一切后果。如因下载或使用国广鑫华服务而对计算机系统造成的损坏或数据的丢失等，用户须自行承担全部责任。
        </p>
        <h2>7. 服务终止 </h2>
        <p className="text">
          7.1
          国广鑫华有权在任何时候，暂时或永久地修改或终止国广鑫华服务（或任何一部分），无论是否通知用户，国广鑫华对国广鑫华服务的修改或终止对用户和任何第三人不承担任何责任。
        </p>
        <p className="text">
          7.2
          国广鑫华有权基于任何正当理由，终止用户的帐号、密码或使用国广鑫华服务，或删除、转移用户存储、发布在国广鑫华服务的内容，国广鑫华采取上述行为均不需通知，并且对用户和任何第三人不承担任何责任。
        </p>
        <h2>8. 通知 </h2>
        <p className="text">
          8.1
          本协议项下国广鑫华对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达用户。
        </p>
        <h2>9. 法律的适用和管辖 </h2>
        <p className="text">9.1 国广鑫华对本协议拥有最终解释权。</p>
        <p className="text">
          9.2
          本条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。
        </p>
        <p className="text" style={{ marginBottom: 50 }}>
          9.3
          如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，则争议各方一致同意将发生的争议提交北京市石景山区人民法院诉讼解决。
        </p>
        <h1>特别提示：</h1>
        <p className="text" style={{ fontSize: 20, fontWeight: 700 }}>
          请您本人务必认真阅读上述条款，只有在您本人真实意愿表示同意接受上述条款，未受到任何胁迫、欺诈的情况下，对本协议予以“接受同意”，并将您本人相关身份信息等交由国广鑫华公司审核通过后，则国广鑫华公司与您本人方均受上述条款约束。
        </p>
      </div>
    </>
  );
};
export default Terms;
