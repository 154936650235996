import React from "react";
import { Col, Row, Layout, Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import Item from "./Item";
import { Mobile } from "@/utils/window";
import styles from "./index.module.css";
import "./index.css";

import { weibo, weixin } from "@/utils/image";

const Footer = () => {
  const navigate = useNavigate();
  const isMobile = Mobile();
  const { Panel } = Collapse;

  let that = window;

  const data = [
    {
      title: "联系我们",
      contents: [
        { name: "电话：010-59916723" },
        { name: "邮箱：cibnkaibo@cbnlive.cn" },
        { name: "地址：北京市石景山区八宝山街道鲁谷路74号瑞达大厦22层" },
      ],
      offset: 4,
      span: 5,
    },
    {
      title: "简介",
      contents: [
        { name: "公司简介", path: "/info#desc" },
        { name: "企业文化", path: "/info#culture" },
        { name: "发展历程", path: "/info#course" },
      ],
      offset: 1,
      span: 4,
    },
    {
      title: "合作",
      contents: [
        {
          name: "商家入驻",
          path: "/study/rule/article/FaeLwtfpfWRyICbG",
          outline: true,
        },
        {
          name: "主播入驻",
          path: "/anchor",
        },
        {
          name: "供应商入驻",
          path: "/study/rule/article/dCRJIvOXShYUfrsq",
          outline: true,
        },
      ],
      offset: 0,
      span: 4,
    },
    {
      title: "法律信息",
      contents: [
        { name: "服务协议", path: "/terms" },
        { name: "隐私政策", path: "/privacy" },
        // { name: "知识产权", path: "" },
      ],
      offset: -4,
      span: 2,
    },
  ];

  return (
    <div>
      {isMobile ? (
        <div>
          <div className={styles.top}>
            <div className={styles.topLeft}>关注我们</div>
            <div className={`${styles.topRight}`}>
              <img className={`${styles.img} pointer`} src={weibo} alt="" />
              <img className={`${styles.img} pointer`} src={weixin} alt="" />
            </div>
          </div>
          <Collapse expandIconPosition="end">
            {data.map((item, key) => {
              return (
                <Panel header={item.title} key={key}>
                  {item.contents.map((v) => {
                    return (
                      <p
                        className={v.path ? "pointer" : ""}
                        onClick={
                          v.path
                            ? () =>
                                v.outline
                                  ? (that.location.href = v.path)
                                  : navigate(`${v.path}`)
                            : null
                        }
                        key={v.name}
                      >
                        {v.name}
                      </p>
                    );
                  })}
                </Panel>
              );
            })}
          </Collapse>
          <div className={styles.copyright}>
            <p>国广鑫华科技（北京）有限公司</p>
            <a style={{ color: "black" }} href="http://beian.miit.gov.cn/">
              京ICP备2023006731号-1
            </a>
          </div>
        </div>
      ) : (
        <div>
          <Layout.Footer className={styles.footer}>
            <div className={styles.layout}>
              <div className={styles.divider}>
                <Row>
                  {data.map((item) => {
                    return (
                      <Col
                        offset={item.offset}
                        span={item.span}
                        key={item.title}
                      >
                        <Item title={item.title} contents={item.contents} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div className={styles.copyright}>
                <p>国广鑫华科技（北京）有限公司</p>
                <p>
                  <a
                    style={{ color: "white" }}
                    href="http://beian.miit.gov.cn/"
                  >
                    京ICP备2023006731号-1
                  </a>
                </p>
              </div>
            </div>
          </Layout.Footer>
        </div>
      )}
    </div>
  );
};

export default Footer;
