import { Layout } from "antd";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "@/pages/Home/index";
import Info from "@/pages/Info";
import Playlet from "@/pages/Playlet";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

function Container() {
  return (
    <Layout>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/info" element={<Info />}></Route>
        <Route path="/playlet" element={<Playlet />}></Route>
        {/* default route */}
        <Route path="*" element={<Navigate to="/" />}></Route>
      </Routes>
      <Footer />
    </Layout>
  );
}

export default Container;
