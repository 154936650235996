import React from "react";
import { Col, Row, Layout } from "antd";
import Title from "@/components/Title";
import Carousel from "./Carousel";
import { Mobile } from "@/utils/window";
import { playletPrefix } from "@/utils/image";

import styles from "./index.module.css";

const { Content } = Layout;
const Playlet = () => {
  const isMobile = Mobile();

  return (
    <>
      <div className={isMobile ? "" : styles.main}>
        <Content>
          <Row>
            <div className={styles.layout}>
              <Col span={24}>
                <Title
                  isMobile={isMobile}
                  titleColor="black"
                  underLineColor="red"
                >
                  短剧项目
                </Title>
              </Col>
              <Col span={24}>
                <img
                  className={styles.descImg}
                  src={playletPrefix + "mp1.png"}
                  alt=""
                />
              </Col>
              <Col span={24}>
                <div className={styles.descContent}>
                  <p className="text">
                    我国网络微短剧市场经历近两年快速发展，规模在2023呈现爆发式增长。据第三方数据挖掘和分析机构艾媒咨询最新发布的《2023-2024年中国微短剧市场研究报告》显示，2023年中国网络微短剧市场规模为373.9亿元，同比增长267.65%。
                    <br />
                  </p>
                  <p className="text">
                    国广鑫华科技（北京）有限公司作为国广东方网络（北京）有限公司的控股子公司，依托大股东中国广电的深厚背景，主动融入中国广电“圆心战略”，与杭州作客文化传媒有限公司携手进军微短剧市场，力求满足大众需求，在短剧新赛道中把好导向关、内容关、人员关、宣传关。
                    <br />
                  </p>
                </div>
              </Col>
            </div>
          </Row>
          <Row className={isMobile ? "" : styles.pay}>
            <div className={styles.layout}>
              <Col span={24}>
                <Title
                  isMobile={isMobile}
                  titleColor="black"
                  underLineColor="red"
                >
                  付费模式
                </Title>
              </Col>
              <Col span={24} className={styles.marginBottom}>
                <Row>
                  <Col
                    offset={isMobile ? 0 : 1}
                    span={isMobile ? 24 : 13}
                    order={isMobile ? 1 : 2}
                    style={isMobile ? {} : { height: "100%" }}
                  >
                    <img
                      className={styles.payImg}
                      src={playletPrefix + "vip1.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    style={isMobile ? {} : { width: 832, height: 300 }}
                    span={isMobile ? 24 : 10}
                    order={isMobile ? 2 : 1}
                    className={`text ${styles.payContent}`}
                  >
                    <p className="text">
                      中国的内容付费市场近些年得到了较快的发展，如：知识付费、阅读付费、音乐付费等，已经成为内容变现的一种主流方式。短剧会员付费的商业逻辑完全等同于网络小说的会员付费，中广剧场通过广告投流获得用户的“试看”，然后用会员付费的形式变现。
                    </p>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
          <Row>
            <Col offset={2} span={20}>
              <Carousel />
            </Col>
          </Row>
          <br />
        </Content>
      </div>
    </>
  );
};
export default Playlet;
