const prefix = "https://img.cbnlive.cn/cbnlive/www/image/";

export const anchor = prefix + "anchor.png";
export const anchorMini = prefix + "anchor.png";
export const indent = prefix + "indent.png";
export const indentMini = prefix + "indentMini.png";
export const supplier = prefix + "supplier.png";
export const supplierMini = prefix + "supplierMini.png";

export const desc = prefix + "desc.svg";
export const course = prefix + "course.svg";
export const coursecut = prefix + "coursecut.svg";

export const iconCool = prefix + "iconcool.svg";
export const iconTencent = prefix + "icontencent.svg";
export const iconYouku = prefix + "iconyouku.svg";
export const weibo = prefix + "iconweibo.svg";
export const weixin = prefix + "iconweixin.svg";
export const cibn = prefix + "cibn.svg";
export const logoimg = prefix + "logotop.svg";
export const menuOpen = prefix + "menuOpen.svg";
export const menuClose = prefix + "menuClose.svg";

// playlet
export const playletPrefix = prefix + "playlet_"